import React from 'react';
import Main_Route from './Routes/Main_Route';


const App = () => {
  return (
      <Main_Route />
  );
};

export default App;
