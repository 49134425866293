import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../ReduxStore/Slice/Staff/staffSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const [getProfileDetails, setGetProfileDetails] = useState([]);

  const id = JSON.parse(localStorage.getItem("staffDetails")).id;

  const Profile = async (e) => {
    const req = { id: id };
    await dispatch(getProfile(req))
      .unwrap()
      .then(async (response) => {
        if (response.status) {
          setGetProfileDetails(response.data);
        } else {
          setGetProfileDetails([]);
        }
      })
      .catch((error) => {
        return;
      });
  };

  useEffect(() => {
    Profile();
  }, []);

  return (
    <div className="container">
      <div className="main-body mt-3">
        <div className="card">
          <div className="card-body">
            <div className="dastyle-profile">
              <div className="row py-3">
                <div className="col-lg-4 align-self-center mb-3 mb-lg-0">
                  <div className="dastyle-profile-main justify-content-center">
                    <div className="dastyle-profile-main-pic">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar7.png"
                        alt="Admin"
                        className="rounded-circle"
                        width={200}
                      />
                    </div>
                    <div className="dastyle-profile_user-detail">
                      {/* <h5 className="dastyle-user-name">{getProfileDetails && getProfileDetails.first_name}</h5> */}
                      {/* <p className="mb-0 dastyle-user-name-post">UI/UX Designer, India</p> */}
                    </div>
                  </div>
                </div>
                {/*end col*/}
                <div className="col-lg-8 ml-auto align-self-center">
                  <ul className="list-unstyled personal-detail mb-0">
                    <li className=" mb-3">
                      <i className=" ti-user mr-2 text-secondary font-22 align-middle" />{" "}
                      <b>Full Name </b>
                      {getProfileDetails &&
                        getProfileDetails.first_name +
                          " " +
                          getProfileDetails.last_name}
                    </li>
                    <li className="mb-3">
                      <i className="fa-regular fa-phone mr-2 text-secondary font-22 align-middle" />{" "}
                      <b>phone </b>:{" "}
                      {getProfileDetails && getProfileDetails.phone}
                    </li>
                    <li className="mb-3">
                      <i className="fa-regular fa-envelope text-secondary font-22 align-middle mr-2" />{" "}
                      <b>Email </b>:{" "}
                      {getProfileDetails && getProfileDetails.email}
                    </li>
                    <li className="mb-3">
                      <i className="fa-regular fa-power-off text-secondary font-22 align-middle mr-2" />{" "}
                      <b>Status </b>:{" "}
                      {getProfileDetails && getProfileDetails.status}
                    </li>
                    <li className="">
                      <button className="btn btn-info">
                        <i className="fa fa-edit pe-2"></i>Edit{" "}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {/*end row*/}
            </div>
            {/*end f_profile*/}
          </div>
        </div>

        {/* <div className="row gutters-sm">
                    <div className="col-md-4 mb-3">
                        <div className="card">
                            <div className='row'>
                                
                            </div>
                            <div className="card-body">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img
                                        src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                        alt="Admin"
                                        className="rounded-circle"
                                        width={150}
                                    />
                                    <div className="mt-3">
                                        <h4>{getProfileDetails && getProfileDetails.first_name}</h4>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-8">
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Full Name</h6>
                                    </div>
                                    <div className="col-sm-9 ">{getProfileDetails && getProfileDetails.first_name + " " +  getProfileDetails.last_name}</div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Email</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">{getProfileDetails && getProfileDetails.email}</div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Phone</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">{getProfileDetails && getProfileDetails.phone}</div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Status</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">{getProfileDetails && getProfileDetails.status}</div>
                                </div>
                               
                                 
                                <hr />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <a
                                            className="btn btn-info "
                                            target="__blank"
                                            href="https://www.bootdey.com/snippets/view/profile-edit-data-and-skills"
                                        >
                                            Edit
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default Profile;
