const FirstNameValidation = "First Name is required";
const LastNameValidation = "Last Name is required";
const EmailValidation = "Invalid email address";
const PhoneValidation = "Phone is required";
const PasswordValidation = "Password must be at least 8 characters";
const RoleValidation = "Role is required";
const StatusValidation = "Status is required";
const PasswordIsRequire = "Password is required";
const EmailIsRequire = "Email is required";


export default {FirstNameValidation,LastNameValidation, EmailValidation, PhoneValidation, PasswordValidation, RoleValidation, StatusValidation ,PasswordIsRequire,EmailIsRequire};